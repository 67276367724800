import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  carrierLogo: {
    maxWidth: 250,
    maxHeight: 250,
  },
  modalItem: {
    alignSelf: 'center',
    textAlign: 'center',
  },
}));
