import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  phoneInput: {
    marginBottom: 24,

    '& .PhoneInputInput': {
      color: '#000',
      border: '1px solid #4f4f4f',
      backgroundColor: 'transparent',
      width: '100%',
      height: 'auto',
      padding: '13px 16px',
      fontSize: '14px',
      lineHeight: '20px',

      '& input:focus': {
        outline: 'none',
      },
    },
  },
  fildMargin: {
    marginTop: '20px',
  },
}));
